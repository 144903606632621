import { useSettingsStore } from "~/store/settings";
import { Option } from "~/types/general";
import { CustomerType } from "~/types/customers";

export const useGetCustomerTypes = (): Option[] => {
  const settingsStore = useSettingsStore();

  return settingsStore.settings?.customers?.roles_labels
    ? Object.entries(settingsStore.settings?.customers?.roles_labels)?.map(
        ([key, value]) => ({
          name: value,
          value: key,
        })
      )
    : [
        { name: "Customer", value: CustomerType.INDIVIDUAL },
        { name: "Company", value: CustomerType.COMPANY },
        { name: "Staff", value: CustomerType.STAFF },
        { name: "Contact", value: CustomerType.CONTACT },
      ];
};
